import './Main.css';
import logo from '../../assets/images/logo.png'
import bg from '../../assets/images/bg.png'
import bgMobile from '../../assets/images/bgMobile.png'
function Main() {
    return (
        <div className="main">
            <a href='https://lk.polza.tech' target='_blank' rel='noreferrer'>
                <img alt='' src={logo} className='main__img'></img>
            </a>
            <p className='main__title'>Домен подключен</p>
            <p className='main__subtitle'>Пожалуйста, перейдите в настройки магазина и завершите настройку в разделе «Домен»</p>
            <img alt='' src={bg} className='main__bg main__bg_pc'></img>
            <img alt='' src={bgMobile} className='main__bg main__bg_mobile'></img>
            <a className='main__info-button' href='https://info.polza.tech/online-shop/domain-setup/#connect-to-polza' target='_blank' rel='noreferrer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                    <path d="M18.5645 18.5644C18.7846 17.6157 19.3291 16.7737 20.104 16.1838C20.8789 15.5939 21.8355 15.2932 22.8086 15.3335C23.8879 15.2738 24.947 15.6432 25.7549 16.3614C26.5628 17.0795 27.0539 18.088 27.1211 19.1669C27.1211 22.0494 23.0003 23.0002 23.0003 24.9169" stroke="#111859" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M25 30.0027C24.9995 30.398 24.8818 30.7844 24.6619 31.1129C24.4419 31.4414 24.1295 31.6973 23.7642 31.8484C23.3988 31.9994 22.9969 32.0388 22.6092 31.9616C22.2215 31.8843 21.8653 31.6939 21.5858 31.4143C21.3062 31.1348 21.1158 30.7787 21.0386 30.3909C20.9613 30.0032 21.0007 29.6013 21.1517 29.2359C21.3028 28.8706 21.5588 28.5582 21.8873 28.3383C22.2158 28.1183 22.6021 28.0006 22.9974 28.0001C23.2605 27.9998 23.5211 28.0514 23.7642 28.1519C24.0073 28.2524 24.2282 28.3999 24.4142 28.5859C24.6002 28.7719 24.7477 28.9928 24.8483 29.2359C24.9488 29.479 25.0003 29.7396 25 30.0027Z" fill="#111859" />
                    <path d="M8.87705 32.8866C6.39729 29.3471 5.33415 25.0068 5.89741 20.7219C6.46066 16.4371 8.6094 12.5189 11.9197 9.74053C15.2299 6.96215 19.4613 5.52532 23.7789 5.71355C28.0965 5.90178 32.1868 7.70138 35.2427 10.7573C38.2986 13.8132 40.0982 17.9035 40.2865 22.2211C40.4747 26.5387 39.0379 30.7701 36.2595 34.0804C33.4811 37.3906 29.563 39.5394 25.2781 40.1026C20.9933 40.6659 16.6529 39.6027 13.1134 37.123L7.66674 38.3333L8.87705 32.8866Z" stroke="#111859" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </a>
        </div>
    );
}

export default Main;